import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useIntl } from "react-intl";
import { ajaxBlackout, Modal } from "../../../../_common";

const ResumoLeadIAModal = ({ setModalLigacaoIsOpen, leadId }) => {
  const intl = useIntl();
  const [resumoIA, setResumoIA] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      const fetchData = async () => {
        setLoading(true);
        ajaxBlackout.show();
        try {
          const resp = await axios.get(`Api/Pipeline/Lead/Resumo?Id=${leadId}`);
          setResumoIA(resp.data);
        } catch (error) {
          setResumoIA(null);
          console.error("Erro ao buscar dados:", error);
        } finally {
          setLoading(false);
          ajaxBlackout.hide();
        }
      };

      fetchData();
    },
    [leadId]
  );

  if (loading) {
    return null;
  }

  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "Resumo Lead IA" })}
      label={intl.formatMessage({ defaultMessage: "Resumo Lead IA" })}
      open
      mostraBotaoFechar
      onRequestClose={() => setModalLigacaoIsOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <div style={{ margin: "20px" }}>
        {resumoIA || intl.formatMessage({ defaultMessage: "Nenhum resumo disponível" })}
      </div>
    </Modal>
  );
};

ResumoLeadIAModal.propTypes = {
  setModalLigacaoIsOpen: PropTypes.func.isRequired,
  leadId: PropTypes.number.isRequired,
};

export default ResumoLeadIAModal;
