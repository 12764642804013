import React, { useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import axios from "axios";
import { Button, Dialog, DialogTitle, DialogActions, Typography } from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";
import { Formik, Form } from "formik";
import useSWR from "swr";

import { AjaxBlackout, Flex, createSnackbar } from "../../../../_common";
import FormItem from "./FormItem";
import Section from "./Section";
import { useUsuario } from "../../../../hooks";
import { SinalizarLeadValidator, FieldShape, FieldShapeEmail, FieldShapeTelefone, TIPOS_DADO } from "./services";

const getFieldParentByName = (name = "") => (typeof name === "string" ? name.substring(0, name.lastIndexOf(".")) : "");

function LeadSinalizarSearchingModal({ leadId, isOpen, onClose }) {
  const intl = useIntl();
  const validator = new SinalizarLeadValidator(intl);
  const { id: usuarioId, empresaClienteId } = useUsuario();
  const [camposSinalizadosNames, setCamposSinalizadosNames] = useState([]);

  // TODO: usar endpoint próprio para carregar esses dados
  const { data: empresa, error: errorEmpresa } = useSWR(`/api/pipeline/lead/empresa?id=${leadId}`);
  const { data: contatos, error: errorContatos } = useSWR(`/api/pipeline/lead/contatos?id=${leadId}`);
  const isLoading = (!empresa && !errorEmpresa) || (!contatos && !errorContatos);

  if (isLoading) {
    AjaxBlackout.Show();
    return null;
  }

  AjaxBlackout.Hide();

  const lead = { ...empresa, contatos };

  // TODO: será usado em breve
  // function confirmaSinalizar(values) {
  //   createDialogConfirm({
  //     title: intl.formatMessage({ defaultMessage: "Confirmar Alteração" }),
  //     text: intl.formatMessage({
  //       defaultMessage:
  //         "As informações alteradas na tela de sinalização de inconsistência de dados serão alteradas no Lead. Deseja realmente alterar os dados do Lead e enviar para análise de inconsistência?",
  //     }),
  //     acceptLabel: intl.formatMessage({ defaultMessage: "Confirmar" }),
  //     cancelLabel: intl.formatMessage({ defaultMessage: "Cancelar" }),
  //     callback: accepted => accepted && sinalizar(values),
  //     dialogProps: {
  //       maxWidth: "sm",
  //       fullWidth: true,
  //     },
  //   });
  // }

  async function sinalizar(values) {
    const dados = {
      lead: {
        id: leadId,
        camposSinalizados: camposSinalizadosNames.map(name => get(values, name)),
      },
      userId: usuarioId,
      searchingId: lead.searchingId,
      empresaClienteId,
    };

    try {
      AjaxBlackout.Show();
      await axios.post("/Api/Pipeline/Searching/SinalizarInconsistencia", dados);

      createSnackbar(
        intl.formatMessage({
          // TODO: Será usado em breve
          // defaultMessage: "A sinalização será avaliada e sua conta receberá 1 crédito caso seja aprovada.",
          defaultMessage: "Sua conta do searching recebeu 1 crédito pela sinalização deste lead.",
        })
      );
      onClose();
      document.dispatchEvent(new Event("detalhe-lead/painel/reload"));
      document.dispatchEvent(new Event("detalhe-lead/timeline/reload"));
    } catch ({
      response: {
        data: { ExceptionMessage },
      },
      message,
    }) {
      const msg = ExceptionMessage || message;
      createSnackbar(msg);
    } finally {
      AjaxBlackout.Hide();
    }
  }

  function onClickCheckbox({ isChecked, name }, bag) {
    const parentName = getFieldParentByName(name);

    if (isChecked) {
      setCamposSinalizadosNames([...camposSinalizadosNames, parentName]);
      return;
    }

    setCamposSinalizadosNames(camposSinalizadosNames.filter(n => n !== name));

    const initialValue = get(INITIAL_VALUES, name);
    bag.setFieldValue(name, initialValue);
    bag.setFieldError(name, "");
    bag.setFieldTouched(name, false);
  }

  const INITIAL_VALUES = {
    site: lead.site ? FieldShape({ originalValue: lead.site, tipoDado: TIPOS_DADO.Site }) : null,
    telefones: lead.telefones ? lead.telefones.map(tel => FieldShapeTelefone(tel, null)) : null,
    contatos: lead.contatos
      ? lead.contatos.map(({ id: contatoId, telefones, emails }) => ({
          id: contatoId,
          emails: emails
            ? emails.filter(email => !!email.endereco).map(email => FieldShapeEmail(email, contatoId))
            : null,
          telefones: telefones ? telefones.map(tel => FieldShapeTelefone(tel, contatoId)) : null,
        }))
      : null,
  };

  const initialContatos = INITIAL_VALUES.contatos;
  const mostraContatos =
    initialContatos.length && initialContatos.some(({ telefones, emails }) => telefones?.length || emails?.length);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        <span>
          <FormattedMessage defaultMessage="Sinalizar inconsistência de dados" />
        </span>
      </DialogTitle>

      <Formik
        id="formSinalizacao"
        initialValues={INITIAL_VALUES}
        onSubmit={(values, { setSubmitting }) => {
          sinalizar(values);
          setSubmitting(false);
        }}
        enableReinitialize
        validateOnBlur
      >
        {formikBag => {
          const onCheck = (...args) => onClickCheckbox(...args, formikBag);

          return (
            <Form id="formSinalizacao" noValidate>
              <Section title={intl.formatMessage({ defaultMessage: "Empresa" })}>
                <Flex>
                  {INITIAL_VALUES.telefones &&
                    INITIAL_VALUES.telefones.map((telObj, index) => (
                      <FormItem
                        label={`${intl.formatMessage({ defaultMessage: "Telefone" })} ${index + 1}`}
                        value={telObj.valorIncorreto}
                        name={`telefones[${index}].valorCorrigido`}
                        onCheck={onCheck}
                        validate={value =>
                          validator.validatePhone(value, `telefones[${index}].valorCorrigido`, formikBag)
                        }
                        key={telObj.id}
                      />
                    ))}

                  {INITIAL_VALUES.site && (
                    <FormItem
                      label={intl.formatMessage({ defaultMessage: "Site" })}
                      value={INITIAL_VALUES.site.valorIncorreto}
                      name="site.valorCorrigido"
                      onCheck={onCheck}
                      validate={value => validator.validateBasic(value, "site.valorCorrigido", formikBag)}
                    />
                  )}
                </Flex>
              </Section>

              {!!mostraContatos && (
                <Section title={intl.formatMessage({ defaultMessage: "Contato" })}>
                  {INITIAL_VALUES.contatos.map((contato, i) => {
                    if (!contato.telefones?.length && !contato.emails?.length) return null;

                    return (
                      <div key={contato.id}>
                        <Flex>
                          <Typography color="textSecondary" gutterBottom>
                            {contato.nome || intl.formatMessage({ defaultMessage: "Contato {num}" }, { num: i + 1 })}
                          </Typography>
                        </Flex>
                        <Flex style={{ flexWrap: "wrap" }}>
                          {contato.telefones?.length > 0 &&
                            contato.telefones.map((telContato, j) => (
                              <FormItem
                                label={`${intl.formatMessage({ defaultMessage: "Telefone" })} ${j + 1}`}
                                value={telContato.valorIncorreto}
                                name={`contatos[${i}].telefones[${j}].valorCorrigido`}
                                onCheck={onCheck}
                                validate={value =>
                                  validator.validatePhone(
                                    value,
                                    `contatos[${i}].telefones[${j}].valorCorrigido`,
                                    formikBag
                                  )
                                }
                                key={telContato.id}
                              />
                            ))}
                          {contato.emails?.length > 0 &&
                            contato.emails.map((emailContato, j) => (
                              <FormItem
                                label="E-mail"
                                value={emailContato.valorIncorreto}
                                name={`contatos[${i}].emails[${j}].valorCorrigido`}
                                onCheck={onCheck}
                                validate={value =>
                                  validator.validateEmail(
                                    value,
                                    `contatos[${i}].emails[${j}].valorCorrigido`,
                                    formikBag
                                  )
                                }
                                type="email"
                                key={emailContato.id}
                              />
                            ))}
                        </Flex>
                      </div>
                    );
                  })}
                </Section>
              )}
            </Form>
          );
        }}
      </Formik>

      <DialogActions>
        <Button id="btnCancelar" onClick={onClose}>
          <FormattedMessage defaultMessage="Cancelar" />
        </Button>
        <Button
          id="btnEnviar"
          color="primary"
          variant="contained"
          type="submit"
          form="formSinalizacao"
          disabled={!camposSinalizadosNames.length}
        >
          <FormattedMessage defaultMessage="Enviar" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

LeadSinalizarSearchingModal.propTypes = {
  leadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default LeadSinalizarSearchingModal;
