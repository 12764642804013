/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useIntl } from "react-intl";
import { CircularProgress } from "material-ui";
import { ExactWebPhone, AjaxDiagnostics } from "../../../../_common/";
import { formatPhoneMask } from "../../../../_common/utils/phone";
import "./LigacaoContatosLead.css";

const LigacaoContatosLead = ({ onClose, leadId }) => {
  const intl = useIntl();
  const [modalLigacaoIsMinimized, setModalLigacaoIsMinimized] = useState(false);
  const [carregando, setCarregando] = useState(true);

  const [lista, setLista] = useState({
    nomeLead: "",
    faseLeadId: 10,
    contatos: [],
    telUsuario: "",
    configCustomTelefone: "",
  });
  useEffect(
    () => {
      const recarregarLista = async () => {
        setCarregando(() => true);
        await axios
          .get(`/Api/Pipeline/Lead/BuscarContatosTelefones?leadId=${leadId}`)
          .then(response => {
            setLista(response.data);
          })
          .catch(error => {
            console.error("Erro na solicitação BuscarContatosTelefones:", error);
            AjaxDiagnostics.AlertWarning(
              intl.formatMessage({
                defaultMessage:
                  "Por favor, verifique junto ao gerente/suporte as configurações para realização de chamadas pelo software.",
              }),
              true
            );
            onClose();
          })
          .finally(() => setCarregando(() => false));
      };
      recarregarLista();
    },
    [leadId]
  );

  const handleFecharJanelaLigacoes = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleMinimizarModal = () => {
    setModalLigacaoIsMinimized(current => !current);
  };
  const handleLigacao = async contato => {
    if (ExactWebPhone().isEnabled()) {
      ExactWebPhone().bringToFrontWindow();
      if (contato.provedor === "" || contato.provedor === undefined) {
        await ExactWebPhone().callPhone({ ...contato, leadId, nomeLead: lista.nomeLead });
      } else {
        await ExactWebPhone().callPhoneTeste(contato.provedor.toString());
      }
    }
  };

  return (
    <div id="divTelefonesLigar" className="window-totalvoice-container">
      <div className="window-totalvoice-dadosligacao" style={{ display: "flex", flexDirection: "column" }}>
        <div
          className="window-totalvoice-dadosligacao"
          style={{
            height: "47px",
            width: "418px",
            padding: "0 15px",
            border: "1px solid #53a93f",
            backgroundColor: "#53a93f",
            boxShadow: "none",
            color: "white",
          }}
        >
          <div id="centralLigacoesTotalvoiceCabecalho" style={{ display: "flex", height: "100%" }}>
            <div
              style={{
                lineHeight: "initial",
                display: "flex",
                flexDirection: "column",
                marginRight: "5px",
                width: "100%",
              }}
              className="truncate"
            >
              <div
                style={{
                  lineHeight: "initial",
                  margin: "auto 0",
                  fontSize: "20px",
                  marginTop: "10px",
                  fontWeight: 300,
                }}
                className="truncate"
              >
                {intl.formatMessage({ defaultMessage: "Ligar para" })}
              </div>
            </div>
            <span style={{ marginLeft: "auto", display: "flex" }}>
              <div
                id="btnMinimizarCentralLigacoes"
                className="btnMinimizar-window-ligacao"
                style={{ fontWeight: "bold", margin: "auto" }}
                onClick={() => handleMinimizarModal()}
              >
                <div style={{ color: "#fff", display: "flex", cursor: "pointer", paddingRight: "6px" }}>
                  <i className="material-icons">remove</i>
                </div>
              </div>
              <div
                id="btnFecharCentralLigacoes"
                style={{ margin: "auto" }}
                onClick={() => handleFecharJanelaLigacoes()}
              >
                <div style={{ color: "#fff", display: "flex", cursor: "pointer" }}>
                  <i className="material-icons">close</i>
                </div>
              </div>
            </span>
          </div>
        </div>
        {!modalLigacaoIsMinimized &&
          (carregando ? (
            <div
              className="window-totalvoice-dadosligacao-content"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                className="text-center"
                id="loadingWindowTotalvoiceDadosligacaoContent"
                style={{ display: "block", margin: "auto" }}
              >
                <CircularProgress />
              </div>
            </div>
          ) : (
            <div
              className="window-totalvoice-dadosligacao-content"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                id="windowTotalvoiceDadosligacaoContent"
                style={{
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  padding: "5px 0 0 15px",
                }}
              >
                <div style={{ display: "flex", flex: "none", paddingBottom: "6px" }}>
                  <div style={{ marginRight: "5px" }}>
                    <i className="material-icons" style={{ paddingTop: "4px", color: "black", fontSize: "23px" }}>
                      business
                    </i>
                  </div>
                  <div className="truncate" style={{ marginTop: "4px", marginLeft: "11px" }}>
                    <span
                      id="nomeEmpresaCentralTelefonica"
                      style={{ color: "black", fontFamily: "Arial", fontSize: "17px" }}
                    >
                      {lista.nomeLead}
                    </span>
                  </div>
                </div>

                <div id="listaContatosCentralTelefonica" style={{ paddingBottom: "10px" }}>
                  {lista.contatos.map((contato, indexContato) => (
                    <div key={indexContato}>
                      <div className="truncate" style={{ color: "black" }}>
                        {contato.cargo ? `${contato.nome} - ${contato.cargo}` : contato.nome}
                      </div>
                      <div style={{ marginBottom: "7px", marginTop: "4px" }}>
                        {contato.telefones.map((telefone, indexTelefone) => (
                          <div
                            key={indexTelefone}
                            className="telefoneContato"
                            style={{ display: "flex", flexDirection: "column", marginBottom: "4px" }}
                          >
                            <div style={{ display: "flex", paddingTop: "1px" }}>
                              <i className="material-icons" style={{ fontSize: "18px", marginRight: "11px" }}>
                                phone_in_talk
                              </i>
                              <span id="numeroLigacao" style={{ fontSize: "14px", width: "309px" }}>
                                {formatPhoneMask({ ddi: telefone.ddi, phone: telefone.numero, showDDI: true })}{" "}
                                {telefone.principal ? intl.formatMessage({ defaultMessage: "(Principal)" }) : ""}
                              </span>
                              <button
                                style={{ cursor: "pointer" }}
                                className="btn-call-phone txtLigarCommonClassSelector"
                                onClick={() => handleLigacao(telefone)}
                              >
                                {intl.formatMessage({ defaultMessage: "Ligar" })}
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  className="finalizar-chamada"
                  id="btnEncerrarLicacao"
                  style={{ display: "none", marginLeft: "auto", marginTop: "auto", padding: "10px 20px" }}
                  role="button"
                  tabIndex="0"
                >
                  <span>{intl.formatMessage({ defaultMessage: "Desligar" })}</span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
LigacaoContatosLead.propTypes = {
  onClose: PropTypes.func.isRequired,
  leadId: PropTypes.string,
};
export default LigacaoContatosLead;
