import { get } from "lodash";

import { onlyDigits, validarEmail } from "../../../../_common/utils/string";

export const TIPOS_DADO = {
  Telefone: 1,
  Site: 2,
  TelContato: 3,
  EmailContato: 4,
};

export const FieldShape = ({ originalValue = "", id = null, contatoId = null, tipoDado = -1 }) => ({
  tipoDado,
  valorIncorreto: originalValue,
  valorCorrigido: originalValue,
  contatoId,
  id,
});

export const FieldShapeEmail = (email = {}, contatoId) =>
  email
    ? FieldShape({
        originalValue: email.endereco,
        id: email.id,
        contatoId,
        tipoDado: TIPOS_DADO.EmailV2Contato,
      })
    : null;

export const FieldShapeTelefone = (tel = {}, contatoId) =>
  tel
    ? FieldShape({
        originalValue: tel.numero,
        id: tel.id,
        contatoId,
        tipoDado: contatoId ? TIPOS_DADO.TelContato : TIPOS_DADO.Telefone,
      })
    : null;

export class SinalizarLeadValidator {
  constructor(intl) {
    this.intl = intl;
  }

  validateBasic(value, name, { touched, initialValues }) {
    if (get(touched, name)) {
      if (!value || !value.trim()) {
        return this.intl.formatMessage({ defaultMessage: "Campo obrigatório" });
      }

      const old = get(initialValues, name);
      if (value === old) {
        return this.intl.formatMessage(
          { defaultMessage: "Deve ser diferente do valor antigo: {oldValue}" },
          {
            oldValue: old,
          }
        );
      }
    }
    return "";
  }

  validatePhone(value, name, { touched, initialValues }) {
    let error = "";
    error = this.validateBasic(value, name, { touched, initialValues });

    if (error) {
      return error;
    }

    if (onlyDigits(value) !== value) {
      error = this.intl.formatMessage({ defaultMessage: "Apenas números." });
      return error;
    }

    error = value.length < 8 && this.intl.formatMessage({ defaultMessage: "Insira um número válido." });
    return error;
  }

  validateEmail(value, name, { touched, initialValues }) {
    let error = "";
    error = this.validateBasic(value, name, { touched, initialValues });

    if (error) return error;

    error = !validarEmail(value) && this.intl.formatMessage({ defaultMessage: "Insira um email válido." });
    return error;
  }
}
