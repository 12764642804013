import React from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { MenuItem } from "material-ui";

import { Modal, Tipografia } from "../../../../_common";
import { renderSelectField, renderTextField } from "../../../../_common/utils/inputs-redux-form";
import injectSheet from "../../../../_common/hocs/injectSheet/injectSheet";

const styles = {
  textoAviso: { paddingTop: 16, maxWidth: 600 },
  selectMotivo: { width: "100% !important", marginTop: 8 },
  selectMotivoMenuItem: {
    whiteSpace: "normal !important", // Resetado whiteSpace para menuItem não ultrapassar o tamanho do select e quebrar a linha.
    lineHeight: "initial !important",
    paddingTop: "8px !important",
    paddingBottom: "8px !important",
    minHeight: "0 !important",
  },
};

const validate = values => {
  const errors = {};
  const requiredFields = ["motivoId"];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = "Campo obrigatório.";
    }
  });
  return errors;
};

const ModalDescartarLead = ({ handleSubmit, handleDescartar, motivosDescarte, open, acoes, classes }) => (
  <Modal title="Descartar Lead" open={open} actions={acoes}>
    <div className={classes.textoAviso}>
      <Tipografia tipo="navbar" cor="darkSecondaryText">
        Ao descartar esse lead ele não será mais mostrado no funil, porém seus dados serão mantidos no sistema e será
        possível resgatá-lo futuramente.
      </Tipografia>

      <form id="formModalDescartarLead" onSubmit={handleSubmit(handleDescartar)}>
        <Field
          name="motivoId"
          component={renderSelectField}
          hintText="Defina o motivo do descarte *"
          className={classes.selectMotivo}
        >
          {motivosDescarte.map(motivo => (
            <MenuItem
              className={classes.selectMotivoMenuItem}
              key={motivo.id}
              value={motivo.id}
              primaryText={motivo.descricao}
            />
          ))}
        </Field>
        <Field
          name="observacao"
          floatingLabelFixed
          floatingLabelText="Observações"
          hintText="Escreva aqui suas observações"
          component={renderTextField}
          multiLine
          rows={4}
          rowsMax={4}
          maxLength={1000}
          fullWidth
          hintStyle={{ bottom: 80 }}
        />
      </form>
    </div>
  </Modal>
);

ModalDescartarLead.propTypes = {
  handleSubmit: PropTypes.func,
  handleDescartar: PropTypes.func,
  motivosDescarte: PropTypes.array,
  open: PropTypes.bool,
  acoes: PropTypes.array,
  classes: PropTypes.object,
};

const formModalDescartarLead = reduxForm({
  form: "formModalDescartarLead",
  validate,
});

const enhance = compose(
  injectSheet(styles),
  formModalDescartarLead
);

export default enhance(ModalDescartarLead);
