import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import { defineMessage } from "react-intl";
import { compose } from "recompose";
import { withSnackbar } from "notistack";

import { FlatButton } from "material-ui";

import ModalDescartarLead from "./ModalDescartarLead.jsx";

import { handleCloseModalDescarteLead } from "../../../../store/reducers/detalhesLead/detalhesLeadActions";
import { buscarEtapas } from "../../../base-leads/pipeline/actions";

import createSnackBar from "../../../../_common/utils/snackbar/createSnackbar";
import { AjaxBlackout } from "../../../../_common/index";

class ModalDescartarLeadContainer extends Component {
  handleDescartar = async values => {
    AjaxBlackout.Show();
    const descarteLeadPost = {
      ids: [this.props.leadId],
      motivoId: values.motivoId,
      observacao: values.observacao,
    };

    const resp = await axios.post(`/api/pipeline/lead/descartar`, descarteLeadPost);
    AjaxBlackout.Hide();

    if (resp.data.resultado) {
      createSnackBar(defineMessage({ defaultMessage: "Operação realizada com sucesso" }));
      resp.data.mensagensIntegracao.forEach(message => this.props.enqueueSnackbar(message));

      if (this.props.callback) {
        this.props.callback(resp.data);
      } else if (this.props.origemDetalheLead) {
        window.location.reload();
      } else {
        this.props.descartarLeadNaBaseLeads(+this.props.leadId);
      }

      this.props.handleCloseModalDescarteLead();
    } else {
      createSnackBar(resp.data.message);
    }
  };

  acoes = [
    <FlatButton
      key="btnCancelar"
      label="CANCELAR"
      id="btnCancelar"
      onClick={this.props.handleCloseModalDescarteLead}
      labelStyle={{ fontWeight: "500" }}
    />,
    <FlatButton
      key="btnDescartar"
      label="DESCARTAR"
      id="btnDescartar"
      primary
      type="submit"
      form="formModalDescartarLead"
      labelStyle={{ fontWeight: "500" }}
    />,
  ];

  render() {
    if (this.props.isLoading) {
      AjaxBlackout.Show();
      return null;
    }
    AjaxBlackout.Hide();
    return (
      <ModalDescartarLead
        handleDescartar={this.handleDescartar}
        motivosDescarte={this.props.motivosDescarte}
        open={this.props.open}
        acoes={this.acoes}
      />
    );
  }
}

ModalDescartarLeadContainer.propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  motivosDescarte: PropTypes.array,
  handleCloseModalDescarteLead: PropTypes.func,
  leadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  origemDetalheLead: PropTypes.bool,
  descartarLeadNaBaseLeads: PropTypes.func,
  callback: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
};

const mapStateToProps = state => ({
  open: state.detalhesLead.modalDescarteLead.open,
  isLoading: state.detalhesLead.modalDescarteLead.isLoading,
  motivosDescarte: state.detalhesLead.modalDescarteLead.motivosDescarte,
  leadId: state.detalhesLead.lead.id || state.detalhesLead.modalDescarteLead.leadId,
  origemDetalheLead: !!Object.keys(state.detalhesLead.lead).length,
});

const mapDispatchToProps = dispatch => ({
  handleCloseModalDescarteLead: () => dispatch(handleCloseModalDescarteLead()),
  descartarLeadNaBaseLeads: () => dispatch(buscarEtapas()),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withSnackbar
);

export default enhance(ModalDescartarLeadContainer);
