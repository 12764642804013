import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import StyleIcon from "@material-ui/icons/Style";
import { useIntl } from "react-intl";

import SinalizarSearchingModal from "./SinalizarSearchingModal";
import { ActionButton } from "../../../../components";
import { maybeCallback } from "../../../../_common/utils/fp";
import { createSnackbar } from "../../../../_common";
import { STATUS_SEARCHING } from "../../../../_common/constantes";

SinalizarSearching.propTypes = {
  lead: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    statusSearching: PropTypes.number,
  }),
  closeParentMenu: PropTypes.func,
  callback: PropTypes.func,
};

function SinalizarSearching({ lead, callback, closeParentMenu, ...props }) {
  const intl = useIntl();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const close = response => {
    setIsOpenModal(false);
    maybeCallback(callback)(response);
  };

  const onClick = () => {
    if (lead.statusSearching !== STATUS_SEARCHING.NOVO_LEAD) {
      createSnackbar("Ação não permitida, o lead já teve sinalização de inconsistência informada.");
      return;
    }

    maybeCallback(closeParentMenu)();
    setIsOpenModal(true);
  };

  return (
    <Fragment>
      <ActionButton
        id="btn-sinalizar-inconsistencia"
        type="menu"
        icon={<StyleIcon />}
        label={intl.formatMessage({ defaultMessage: "Sinalizar inconsistência" })}
        onClick={onClick}
        {...props}
      />

      {isOpenModal && <SinalizarSearchingModal isOpen={isOpenModal} leadId={lead.id} onClose={() => close(false)} />}
    </Fragment>
  );
}

export default SinalizarSearching;
