import React from "react";
import PropTypes from "prop-types";
// import { Field } from "formik";
import { withStyles } from "@material-ui/core";
// import { TextField } from "formik-material-ui";

import CheckField from "../../../../_common/components/CheckField/CheckField";

function SinalizarLeadFormItem({ value, label, onCheck, classes, ...props }) {
  return (
    <div className={classes.wrapper}>
      <CheckField
        autoFocus
        autoSelect
        checkboxProps={{
          color: "primary",
          style: { width: "auto", marginRight: 12 },
        }}
        onCheck={isChecked => {
          onCheck({
            isChecked,
            name: props.name,
          });
        }}
      >
        {() => <div className={classes.staticValue}>{value}</div>}

        {/* TODO: Será liberado em breve */}
        {/* {({ isChecked, inputRef }) =>
          isChecked ? (
            <Field
              component={TextField}
              label={label}
              inputRef={inputRef}
              disabled={!isChecked}
              InputLabelProps={{
                shrink: true,
              }}
              {...props}
            />
          ) : (
            <div className={classes.staticValue}>{value}</div>
          )
        } */}
      </CheckField>
    </div>
  );
}

SinalizarLeadFormItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onCheck: PropTypes.func.isRequired,
  InputProps: PropTypes.object,
  name: PropTypes.string,
  classes: PropTypes.object,
};

export default withStyles({
  wrapper: {
    width: "33%",
    marginBottom: 12,
  },
  staticValue: {
    alignSelf: "center",
    paddingTop: 3,
  },
})(SinalizarLeadFormItem);
