import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography, withStyles } from "@material-ui/core";

LeadSinalizarSearchingSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  classes: PropTypes.object,
};

function LeadSinalizarSearchingSection({ title, children, classes }) {
  return (
    <Card className={classes.wrapper}>
      <CardContent className={classes.titleWrapper}>
        <Typography color="textSecondary" variant="h5" className={classes.title}>
          {title}
        </Typography>
      </CardContent>
      <CardContent>{children}</CardContent>
    </Card>
  );
}

export default withStyles(theme => ({
  wrapper: {
    margin: 0,
    boxShadow: "none",
  },
  titleWrapper: {
    padding: 0,
    borderRadius: 0,
  },
  title: {
    background: theme.palette.accent2Color,
    height: 48,
    lineHeight: "48px",
    paddingLeft: 18,
    fontSize: 18,
  },
}))(LeadSinalizarSearchingSection);
